import {createRoot} from "react-dom/client";
import {Navigate, Route, Routes} from "react-router-dom";
import SettingsPage from "./eventer-console/app/pages/accounts/settings/SettingsPage.tsx";
import ImportersPage from "./eventer-console/app/pages/accounts/importers/ImportersPage.tsx";
import App from "./eventer-console/app/App.tsx";
import ToolpadLayout from "./appfw/ui/layouts/toolpad/ToolpadLayout.tsx";
import AccountsGuard from "./eventer-console/app/pages/accounts/AccountsGuard.tsx";
import RootElementWrapper from "./appfw/RootElementWrapper.tsx";
import AuthenticationGuard from "./appfw/authentication/AuthenticationGuard.tsx";
import EventerRoutes from "./EventerRoutes.ts";
import NewAccountPage from "./eventer-console/app/pages/accounts/new/NewAccountPage.tsx";
import SplashScreenOutlet from "./eventer-console/app/SplashScreenOutlet.tsx";
import AccountGuard from "./eventer-console/app/pages/accounts/AccountGuard.tsx";
import EventsFeed from "./eventer-console/app/pages/accounts/events/EventsFeed.tsx";

let AppRoutes =
    <Routes>
        <Route index element={<Navigate to={EventerRoutes.accounts.path()} replace/>}/>
        <Route path={EventerRoutes.accounts.segment()}
               element={<AccountsGuard newAccountPath={EventerRoutes.accounts.new.path()}/>}>
            <Route path={EventerRoutes.accounts.new.segment()} element={<NewAccountPage/>}/>
            <Route path={EventerRoutes.accounts.account.segment()} element={<AccountGuard/>}>
                <Route element={<App/>}>
                    <Route element={<ToolpadLayout/>}>
                        <Route path={EventerRoutes.accounts.account.events.segment()} element={<EventsFeed/>}/>
                        <Route path={EventerRoutes.accounts.account.importers.segment()} element={<ImportersPage/>}/>
                        <Route path={EventerRoutes.accounts.account.settings.segment()} element={<SettingsPage/>}/>
                    </Route>
                </Route>
            </Route>
        </Route>

        {/* Global not found route => general 404 page + specific 404 pages */}
        <Route path="*" element={<Navigate to={EventerRoutes.accounts.path()} replace/>}/>
    </Routes>

const node = document.getElementById('root') as HTMLElement
createRoot(node).render(
    <RootElementWrapper>
        <AuthenticationGuard
            authenticationConfig={{app: "eventer-console", oidcProviders: ["google"]}}
            splashScreenOutlet={<SplashScreenOutlet/>}
        >
            {AppRoutes}
        </AuthenticationGuard>
    </RootElementWrapper>
)