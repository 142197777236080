import {useEventsFeed} from "./useEventsFeed.ts";
import {useEffect, useRef} from "react";
import {useAccount} from "../Accounts.tsx";
import {Box, Typography} from "@mui/material";
import {EventCard} from "./EventCard.tsx";
import {useRefsReady} from "../../../../../appfw/react/useRefsReady.tsx";

export default function EventsFeed() {

    const account = useAccount()
    const eventsFeed = useEventsFeed({pod: account.podName})

    const containerRef = useRef<HTMLDivElement>(null)
    // const topRef = useRef(null)
    const bottomRef = useRef<HTMLDivElement>(null)
    const refsReady = useRefsReady([containerRef, bottomRef])

    const pod = eventsFeed.pod


    useEffect(() => {

        if (!containerRef.current || !bottomRef.current) {
            return
        }

        const observer = new IntersectionObserver(async ([entry]) => {
            console.log("111: useEffect intersection event", entry.isIntersecting, eventsFeed.hasNext)
            if (entry.isIntersecting && eventsFeed.hasNext) {
                console.log("bottomRef is intersecting ==> fetchPage('after')")
                await eventsFeed.loadNext()
            }
        }, {
            root: containerRef.current,
            threshold: 0.1,
        })

        observer.observe(bottomRef.current)

        return () => {
            console.log("111 🧹 Disconnecting observer");
            observer.disconnect();
        };
    }, [refsReady])


    if (pod == undefined || eventsFeed.events == undefined) {
        // TODO: loading spinner
        return <Typography>Lade Events...</Typography>
    }

    return (
        <Box
            ref={containerRef}
            sx={{
                // height: '80vh',
                overflowY: 'scroll', display: 'flex', flexDirection: 'column',
                // border: '2px dashed red', // 👀 sichtbar
            }}
        >
            {/*<div ref={topRef} style={{height: '25', background: 'lightgray'}}>*/}
            {/*    top*/}
            {/*</div>*/}

            {
                eventsFeed.events?.map((event) => {
                    return <EventCard pod={pod} event={event} key={event.subject}/>
                })
            }

            <Box sx={{p: 1}}>
                <div ref={bottomRef} style={{minHeight: '1'}}></div>
            </Box>
        </Box>
    );
}