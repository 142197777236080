import { RefObject, useEffect, useState } from 'react';

export function useRefsReady(refs: RefObject<unknown>[]): boolean {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        let active = true;

        const checkRefs = () => {
            const allReady = refs.every(ref => ref.current !== null);
            if (allReady && active) {
                setReady(true);
            } else if (active) {
                requestAnimationFrame(checkRefs);
            }
        };

        checkRefs();

        return () => {
            active = false;
        };
    }, [refs]);

    return ready;
}
