import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocalEventerPod} from "../../../../../eventer-pod/client/useLocalEventerPod.ts";
import {ResourceWrapper} from "../../../../../eventer-pod/client/ResourceWrapper.ts";
import Session from "../../../../../appfw/authentication/Session.ts";
import {SessionContext} from "../../../../../appfw/authentication/SessionProvider.tsx"
import {FetchPodEventsRequest} from "@eventer/api-client/lib/esm/eventer-api-client.types";

// TODO: later "zustand" to store global states?
let cachedEvents: ResourceWrapper[] | undefined = undefined;

export interface useEventFeedsProps {
    pod: string,
}

export function useEventsFeed(props: useEventFeedsProps) {

    const session = useContext<undefined | null | Session>(SessionContext)
    const {pod} = useLocalEventerPod({pod: props.pod})

    const [events, setEvents] = useState<ResourceWrapper[] | undefined>(cachedEvents)

    const [hasNext, setHasNext] = useState(true)
    const nextUrlRef = useRef<string | undefined>(undefined);

    const loadNext = useCallback(async () => {

        if (!session || !pod) {
            return
        }

        const nextUrl = nextUrlRef.current
        
        let result
        if (nextUrl == undefined) {

            const now = new Date();
            const twoDaysAgo = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() - 2, // 2 Tage zurück
                0, 0, 0, 0         // 0 Uhr
            );

            const request: FetchPodEventsRequest = {
                pod: props.pod,
                startDateMin: twoDaysAgo,
                limit: 10,
                sort: 'startDate_asc',
            }
            result = await pod.loadPodEvents({request: request, sessionId: session.id})
        } else {
            result = await pod.loadNextPodEvents({url: nextUrl, sessionId: session.id})
        }

        if (cachedEvents == null) {
            cachedEvents = result.events
        } else {
            cachedEvents = cachedEvents.concat(result.events)
        }

        nextUrlRef.current = result.nextUrl

        if (result.events.length == 0) {
            setHasNext(false)
        }

        setEvents(cachedEvents)

    }, [pod, session?.id, setHasNext, setEvents])

    useEffect(() => {

        if (events) {
            // already loaded (initially)
            return
        }

        if (!session || !pod) {
            return
        }

        loadNext()

    }, [pod, session?.id])

    return {pod, events, hasNext, loadNext}
}